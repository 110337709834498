import { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import useError from '../../hooks/useError';
import tea from '../../tea.png'

const { ethereum } = window;

function Contact({ quote, address, phone, email, chatroom, CURRENCY, TEA_PRICE, MIN_COUNT, MAX_COUNT, GAS_LIMIT, GAS_PRICE, COINBASE_API_URL, ETHERSCAN_URL, WALLET_ADDRESS, GITHUB_USERNAME}) {
    /* Web3 button*/
    const [error, setError] = useError('');
    const [rates, setRates] = useState([]);
    const [count, setCount] = useState('1');
    const [txHash, setTxHash] = useState('');
  
    const amount = useMemo(() => TEA_PRICE * Number(count), [count]);
    const wei = useMemo(() => Math.round(((amount / Number(rates[CURRENCY])) * 10 ** 18)), [rates, amount]);
  
    const fetchRates = () => new Promise((res, rej) => {
      axios.get(`${COINBASE_API_URL}/exchange-rates`, { params: { currency: 'ETH' } })
        .then(({ data }) => res(data.data.rates))
        .catch((e) => rej(e));
    });
  
    useEffect(async () => {
      setRates(await fetchRates());
    }, []);
  
    const onInput = (e) => {
      const count = Number(e.target.value);
      if (count > MAX_COUNT) {
        setCount(String(MAX_COUNT));
        return;
      }
      if (count < MIN_COUNT) {
        setCount(String(MIN_COUNT));
        return;
      }
      setCount(String(count));
    };
  
    const onMinus = () => {
      if (Number(count) > MIN_COUNT) {
        setCount(String(Number(count) - 1));
      }
    };
  
    const onPlus = () => {
      if (Number(count) < MAX_COUNT) {
        setCount(String(Number(count) + 1));
      }
    };
  
    const onSend = async () => {
      try {
        const [from] = await ethereum.request({
          method: 'eth_requestAccounts',
        });
        const txHash = await ethereum.request({
          method: 'eth_sendTransaction',
          params: [
            {
              from,
              to: WALLET_ADDRESS,
              gas: GAS_LIMIT.toString(16),
              gasPrice: GAS_PRICE.toString(16),
              value: wei.toString(16),
            },
          ],
        });
        setError('');
        setTxHash(txHash);
      } catch (e) {
        setError(e.message);
      }
    };

    return (
        <>
            <section id="contact" className="navbar-is-white text-white section pp-scrollable position-absolute"
                style={{ backgroundImage: "url('img/bg/contact.jpg')" }}>
                <div className="intro">
                    <div className="scroll-wrap">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                    <h2 className="text-white">Contact / 相談</h2>
                                    <p className="text-muted">{quote}</p>
                                    <h3 className="text-white mt-5 pt-5">{address}</h3>
                                    <h3 className="text-white">{phone}</h3>
                                    <p className="text-white mt-3">{email}</p>
                                </div>
                                <div className="col-md-5 offset-md-2">
                                    <h3 className="text-white mt-0">Let's jump on conversation in</h3>
                                    <a target="_blank" rel="noreferrer" href={chatroom} className="btn">My Chat Room</a> 
                                    <div className="mt-5">
                                    <figure className="w-96 flex flex-col text-center bg-slate-200 text-slate-600 rounded-xl px-16 py-16">
                                      <div className="mb-6">
                                        <img className="w-36 h-36 rounded-full mx-auto" src={`https://github.com/${GITHUB_USERNAME}.png`} alt={GITHUB_USERNAME} />
                                      </div>
                                      <div className="tracking-wider text-lg mb-6">
                                        Donate
                                        {' '}
                                        <span className="text-slate-800 font-bold">KEN</span>
                                        {' '}
                                        a Bubble Tea
                                      </div>
                                      <div className="mt-4 mb-12">
                                        <div className="flex flex-row">
                                          <img className="w-8 h-8 mx-auto" src={tea} alt="beer" />
                                          <button type="button" className="bg-slate-200 text-slate-400 hover:bg-slate-300 hover:text-slate-500 w-12 rounded-l cursor-pointer" onClick={onMinus}>
                                            <span className="m-auto text-2xl font-light">-</span>
                                          </button>
                                          <input type="number" className="bg-slate-200 hover:bg-slate-300 w-12 text-center outline-none" value={count} min="1" max="100" onChange={onInput} />
                                          <button type="button" className="bg-slate-200 text-slate-400 hover:bg-slate-300 hover:text-slate-500 w-12 rounded-r cursor-pointer" onClick={onPlus}>
                                            <span className="m-auto text-2xl font-light">+</span>
                                          </button>
                                        </div>
                                      </div>
                                      <div className="text-xs text-slate-600 mb-6">
                                        {`${amount.toLocaleString()} ${CURRENCY} ≈ ${(wei / 10 ** 18).toFixed(9)} ETH`}
                                      </div>
                                      <button type="button" className="btn btn-gradient mb-6" onClick={onSend}>Send ETH</button>
                                      <div className="text-xs text-slate-600">
                                        {!ethereum && (
                                          <>
                                            <a target="_blank" href="https://metamask.io/download/" rel="noopener noreferrer">MetaMask</a>
                                            {' '}
                                            is not installed.
                                          </>
                                        )}
                                        {error}
                                        {txHash && (
                                          <>
                                            Transaction Hash:
                                            {' '}
                                            <a target="_blank" href={`${ETHERSCAN_URL}/tx/${txHash}`} rel="noopener noreferrer">
                                              {txHash.substring(0, 5)}
                                              ...
                                              {txHash.substring(txHash.length - 4)}
                                            </a>
                                          </>
                                        )}
                                      </div>
                                    </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact