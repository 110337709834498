export const main = {
    "name": "Shen  (沈) Tzuchieh  (子捷)",
    "description": "Tech Lead in Tokyo / 東京.",
    "image": "profilepic.jpg",
    "email": "2qov3b@gmail.com",
    "phone": "+81 90 3900 2718",
    "quote": "The universe is a pretty big place. If it's just us.",
    "address": "Tokyo, Japan",
    "website": "https://2qov3b.com",
    "resumedownload": "https://drive.google.com/file/d/xxxxx",
    "social": [
        {
            "name": "linkedin",
            "url": "https://www.linkedin.com/in/tzuchieh-ken-shen-5ba56762/",
            "iconName": "logo-linkedin"
        },
        {
            "name": "github",
            "url": "https://github.com/2qov3b",
            "iconName": "logo-github"
        }
    ]
}

export const about = {
    "bio": "I come from <span class='text-primary'>Taiwan(台灣)</span>.<br><br>Please call me <span class='text-primary'>KEN</span>.<br><br> I'm a <span class='text-primary'>Tech Lead</span> who cares<br /> about the code and the people.",
    "ido": "<p>Web Development</p><p>Full-stack Development</p><p>DevOps and Multi-Cloud</p>",
    "years": 10,
    "image": "img/profilepic.jpg",
    "resumedownload": "https://drive.google.com/file/d/xxxxx",
}

export const works = [
    [
        
        {
            "company": "The Japan Research Institute, Limited",
            "title": "Tech Lead",
            "image": "img/behance.png",
            "years": "July, 2020 - Present",
            "description": "＠Tokyo, Japan <br />• Responsible for testing applications and looking for bugs & monitoring website performance <br />• Responsible for application code and UI/UX design <br />• Built responsive custom WordPress themes from scratch also with DIVI theme <br />• Optimized company website for performance and Search Engine Optimization"
        },
        {
            "company": "WorkFusion Inc.",
            "title": "DevOps Engineer",
            "image": "img/behance.png",
            "years": "August, 2019 - May, 2020",
            "description": "＠Minsk, belarus / Tokyo, Japan <br />• Designing web and mobile apps mockups using Figma <br />• Designed and built the User Interface of the mobile app with React-native <br />• Assist the backend developer on ongoing development projects <br />• Participating in all decision-making processes which includes the database design, tooling, deployment processes etc"
        },
        {
            "company": "Rakuten Group, Inc.",
            "title": "Software Engineer",
            "image": "img/behance.png",
            "years": "April, 2014 - August, 2019",
            "description": "＠Tokyo, Japan <br />• Providing comprehensive maintenance support <br />• Improving the user interface <br />• Building apps for self-health and helping the nation"        },
    ],
    [
        {
            "company": "Syntronix Corp.",
            "title": "Software Engineer",
            "image": "img/behance.png",
            "years": "February, 2010 - March, 2012",
            "description": "＠Taiwan <br />• Optimized company website for performance and Search Engine Optimization <br />• Developed Custom Post Types and created custom fields with ACF plugins <br />• Sliced up design assets from Photoshop files when building custom themes <br />• Performed maintenance tasks on pre-existing sites using the Infinite WP dashboard, such as updating plugins, ensuring backups are running, and running security scans"
        },
    ]
]

export const skills = {
    title: "Making a <span class='text-primary'>Global</span> Impact",
    subtitle: "Developing software for the world of intent is something I'm passionate about and I'm dedicated to making the world of intent easy and affordable for everyone.",
    skills: [
        {
            "title": "Front-end",
            skills: ["JavaScript", "React JS", "Next JS", "HTML/CSS", "SCSS", "UI/UX Design", "Jquery", "Redux"]
        },
        {
            "title": "Back-end",
            skills: ["Node JS", "Express JS", "React Native", "Rest API", "PHP"]
        },
        {
            "title": "Database",
            skills: ["MySQL", "MongoDB", "Firebase", "redis"]
        },
        {
            "title": "Cloud",
            skills: ["Google", "AWS", "Digital ocean", "Heroku", "Vercel"]
        }
    ]
}

export const projects = [
    {
        "title": "SMBC Group: Sustana",
        "category": "Full-stack / Enterprise / SaaS / Cloud / Container",
        "image": "sustana.png",
        "url": "https://www.smbc.co.jp/news/j602435_01.html"
    },
    {
        "title": "XXX",
        "category": "Website",
        "image": "video-calling.jpg",
        "url": ""
    },
    {
        "title": "XXX",
        "category": "Blockchain",
        "image": "web3.png",
        "url": ""
    },
    {
        "title": "XXX",
        "category": "Website",
        "image": "amazon.jpg",
        "url": ""
    },
    {
        "title": "WorkFusion Enterprise",
        "category": "Enterprise / SaaS / Cloud / Container",
        "image": "workfusion.png",
        "url": "https://www.workfusion.com/workfusion-enterprise/"
    },
    {
        "title": "Rakuten Seiyu Netsuper",
        "category": "API Design / Big Data / DevOps",
        "image": "rakutennetsuper.png",
        "url": "https://sm.rakuten.co.jp/search?keyword=%E3%81%95%E3%81%B0"
    },
    {
        "title": "Rakuten Ranking",
        "category": "API Design / Big Data / DevOps",
        "image": "rakutenranking.png",
        "url": "https://ranking.rakuten.co.jp/realtime/"
    },
    {
        "title": "Rakuten Travel",
        "category": "Full-stack / API Design",
        "image": "rakutentravel.png",
        "url": "https://travel.rakuten.co.jp/package/"
    }
]

export const contact = {
    "email": "2qov3b@gmail.com",
    "phone": "+81 90 3900 2718",
    "quote": "The universe is a pretty big place. If it's just us.",
    "address": "Tokyo, Japan",
    "chatroom": "https://subtle-travesseiro-d3426a.netlify.app/",
    "CURRENCY": "JPY",
    "TEA_PRICE": "500",
    "MIN_COUNT": "0",
    "MAX_COUNT": "100",
    "GAS_LIMIT": "30000",
    "GAS_PRICE": "1500000000",
    "COINBASE_API_URL": "https://api.coinbase.com/v2",
    "ETHERSCAN_URL": "https://etherscan.io",
    "WALLET_ADDRESS": "0xd0368eE169498faad1AF9aBFbdC8C9CAE432aBe5",
    "GITHUB_USERNAME": "2qov3b"
}